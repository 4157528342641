import { Component } from '@angular/core';

@Component({
    selector: 'mst-layout-pages',
    template: `
        <mst-header></mst-header>
        <router-outlet></router-outlet>
    `,
    styles: []
})
export class LayoutPagesComponent { }
