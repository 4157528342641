import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { LocalStorageManagementService } from '../services/base/local-storage-management.service';
import { ConstantsService } from '../services/base/constants.service';
import { UtilsService } from '../services/base/utils.service';
import { Constants } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class NutritionistCanViewProgressGuard  {
    constructor(
        private constantsService: ConstantsService,
        private localStorageManagementService: LocalStorageManagementService,
        private utilsService: UtilsService
    ) { }
    canActivate(): Promise<boolean> {
        return new Promise((resolve: any) => {
            this.constantsService.getConstants().subscribe((constants: Record<string, any>) => {
                const rolesMap = this.utilsService.arrayToObject(constants[Constants.USER_ROLES], 'id');
                const activeUser = this.localStorageManagementService.getUser();

                if (rolesMap[activeUser.role_id].tag === Constants.USER_ROLES_MAP.NUTRITIONIST) {
                    activeUser?.can_view_users_progress ? resolve(true) : resolve(false)
                } else {
                    resolve(true);
                }
            })
        })
    }
}
