import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { VERSION } from '@env/version';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentryEnabled) {
    Sentry.init({
        environment: environment.environment,
        release: 'mst-bo-fe@' + VERSION.version,
        dsn: environment.sentryDsn,
        beforeSend(event: any, hint: any) {
            if (hint?.originalException?.toString().includes('401')) {
                return null;
            }
            return event;
        },
    });

    Sentry.setTag('environment', environment.environment);
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: any) => console.error(err));
