import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Constants } from '@app/core/constants/constants';

import { AuthService } from './auth.service';
import { ConstantsService } from './constants.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectService {
    constructor(
        private router: Router,
        private authService: AuthService,
        private constantsService: ConstantsService
    ) {}

    redirectBasedOnUserRole(): void {
        this.constantsService.getConstants().subscribe((constants: any) => {
            const userData = this.authService.getUser();
            const userRole = constants[Constants.USER_ROLES].find((role: any) => role.id === userData.role_id);
            switch (userRole.tag) {
                case Constants.USER_ROLES_TAG.SUPER_ADMIN:
                case Constants.USER_ROLES_TAG.ADMIN:
                    this.router.navigate(['/users-grid']);
                    break;
                case Constants.USER_ROLES_TAG.STANDARD_USER:
                    this.router.navigate(['/users-grid']);
                    break;
                case Constants.USER_ROLES_TAG.NUTRITIONIST:
                    this.router.navigate(['/users']);
                    break;
                default:
                    this.router.navigate(['/login']);
                    break;
            }
        });
    }
}
