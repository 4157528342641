import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/material/material.module';
import { MatDialogModule } from '@angular/material/dialog';

//
// ─── COMPONENTS ──────────────────────────────────────────────────────────────────
//

import { HeaderComponent } from './header/header.component';
import { LayoutLoginComponent } from './layouts/layout-login.component';
import { LayoutPagesComponent } from './layouts/layout-pages.component';
import { ModalAddNutritionistComponent } from './modal-add-nutritionist/modal-add-nutritionist.component';
import { ModalAddVideosComponent } from './modal-add-videos/modal-add-videos.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';

//
// ─── DIRECTIVES ──────────────────────────────────────────────────────────────────────
//

import { AuthorizeExceptDirective } from './directives/authorize-except.directive';
import { AuthorizeOnlyDirective } from './directives/authorize-only.directive';
import { RoleBasedClassDirective } from './directives/role-based-class.directive';
import { SelectComponent } from './select/select.component';
import { SwitchComponent } from './switch/switch.component';
import { TitleComponent } from './title/title.component';

//
// ─── PIPES ──────────────────────────────────────────────────────────────────────
//

import { ModalManageRecipesComponent } from './modal-manage-recipes/modal-manage-recipes.component';
import { ModalManageDocumentsComponent } from './modal-manage-documents/modal-manage-documents.component';
import { TypeIDPipe } from './pipes/type-id.pipe';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { UserTypePipe } from '@app/shared/pipes/user-type.pipe';
import { VideoAttributeTypePipe } from './pipes/video-attribute-type.pipe';
import { DecodeURIComponentPipe } from './pipes/decode-uri-component.pipe';
import { VideosComponent } from './videos/videos.component';
import { ModalAddMsb4uVideosComponent } from './modal-add-msb4u-videos/modal-add-msb4u-videos.component';
import { ModalViewImageComponent } from './modal-view-image/modal-view-image.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
    declarations: [
        // -- COMPONENTS -- //
        HeaderComponent,
        LayoutLoginComponent,
        LayoutPagesComponent,
        ModalAddNutritionistComponent,
        ModalAddMsb4uVideosComponent,
        ModalAddVideosComponent,
        ModalConfirmComponent,
        ModalManageRecipesComponent,
        ModalManageDocumentsComponent,
        ModalViewImageComponent,
        SelectComponent,
        SwitchComponent,
        TitleComponent,
        UploadFilesComponent,
        VideosComponent,
        // -- DIRECTIVES -- //
        AuthorizeExceptDirective,
        AuthorizeOnlyDirective,
        RoleBasedClassDirective,
        // -- PIPES -- //
        TypeIDPipe,
        UserTypePipe,
        VideoAttributeTypePipe,
        DecodeURIComponentPipe,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMaterialTimepickerModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MaterialModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        // -- COMPONENTS -- //
        TitleComponent,
        UploadFilesComponent,
        VideosComponent,
        // -- DIRECTIVES -- //
        AuthorizeExceptDirective,
        AuthorizeOnlyDirective,
        RoleBasedClassDirective,
        // -- PIPES -- //
        TypeIDPipe,
        UserTypePipe,
        DecodeURIComponentPipe,
        NgxMaterialTimepickerModule,
    ],
})
export class SharedModule {}
