import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../services/base/auth.service';
import { RedirectService } from '../services/base/redirect.service';
import { RoleBasedPermissionsService } from '../services/base/role-based-permissions.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionsOnlyGuard  {

    constructor(
        private authService: AuthService,
        private redirectService: RedirectService,
        private roleBasedPermissions: RoleBasedPermissionsService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return new Promise((resolve: any) => {
            const userData = this.authService.getUser();
            if (route.data.allowReporter && userData.is_reporter) {
                resolve(true);
            } else {
                this.roleBasedPermissions.checkAuthorizationOnly(route.data.rolesTag).subscribe(
                    (consent: boolean) => {
                        if (consent) {
                            resolve(true);
                        } else {
                            this.redirectService.redirectBasedOnUserRole();
                            resolve(false);
                        }
                    }, () => {
                        this.router.navigate(['login']);
                        resolve(false);
                    }
                );
            }
        });
    }
}
