<mat-toolbar>
    <div class="mst-header__leftToolbar">
        <span class="mst-header__headerTitle">
            <img src="/assets/icons/icon-text-logo-brand-02.svg" alt="logo" width="" height="" class="mst-logo -logo-text -brand-02" />
        </span>
        <button
            class="mst-usersFiltersTrigger"
            mat-flat-button
            routerLinkActive="mst-activeMenuItem"
            [matMenuTriggerFor]="usersPlansFiltersMenu"
        >
            <span class="mst-usersFiltersTrigger__content">
                UTENTI
                <i class="material-icons">arrow_drop_down</i>
            </span>
        </button>
        <mat-menu #usersPlansFiltersMenu="matMenu">
            <button mat-menu-item *ngIf="!isNutritionist" [routerLink]="['/users-grid']" routerLinkActive="router-link-active">
                TUTTI GLI UTENTI
            </button>
            <button mat-menu-item (click)="navigateToUsers()">TUTTI GLI UTENTI {{ !isNutritionist ? ' (OLD)' : '' }}</button>
            <button mat-menu-item *ngIf="!isNutritionist" [matMenuTriggerFor]="usersTrainingPlanStatusesFiltersMenu">TRAINING</button>
            <button mat-menu-item [matMenuTriggerFor]="usersNutritionPlanStatusesFiltersMenu">NUTRITION</button>
            <button mat-menu-item *ngIf="!isNutritionist" [matMenuTriggerFor]="usersGymMenu">GYM</button>
        </mat-menu>
        <mat-menu #usersTrainingPlanStatusesFiltersMenu="matMenu" class="mst-trainingPlanMenu">
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/training/plan-purchased">PIANO ACQUISTATO</button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/training/plan-ready-to-process">
                PIANO DA PRENDERE IN CARICO
            </button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/training/plan-delivered">PIANO CONSEGNATO</button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/training/renewals-to-handle">
                RINNOVI DA PRENDERE IN CARICO
            </button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/training/renewals-ready">RINNOVI GESTITI</button>
        </mat-menu>
        <mat-menu #usersGymMenu="matMenu" class="mst-trainingPlanMenu">
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/gym-training-ready">PIANO PRONTO</button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/gym-training-renewal">PIANO RINNOVATO</button>
            <!-- <button mat-menu-item routerLinkActive="mst-activeMenuItem">TEMPLATE</button> -->
        </mat-menu>
        <mat-menu #usersNutritionPlanStatusesFiltersMenu="matMenu">
            <button
                mat-menu-item
                routerLinkActive="mst-activeMenuItem"
                *ngIf="!isNutritionist"
                routerLink="users/nutrition/waiting-anamnesis"
            >
                IN ATTESA DI ANAMNESI
            </button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/nutrition/anamnesis-ready">
                ANAMNESI PRONTA
            </button>
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" routerLink="users/nutrition/plan-delivered">
                PIANO CONSEGNATO
            </button>
        </mat-menu>

        <button
            class="mst-usersFiltersTrigger"
            mat-flat-button
            routerLinkActive="mst-activeMenuItem"
            [matMenuTriggerFor]="msbTrainingFiltersMenu"
        >
            <span class="mst-usersFiltersTrigger__content">
                MSB TRAINING
                <i class="material-icons">arrow_drop_down</i>
            </span>
        </button>
        <mat-menu #msbTrainingFiltersMenu="matMenu">
            <button mat-menu-item routerLinkActive="mst-activeMenuItem" [matMenuTriggerFor]="videosFiltersMenu">VIDEO</button>
            <mat-menu #videosFiltersMenu="matMenu">
                <button mat-menu-item *ngIf="!isNutritionist" routerLink="videos-training">TRAINING</button>
                <button mat-menu-item routerLink="videos-msb4you">MSB4YOU</button>
                <button mat-menu-item routerLink="videos-gym">GYM</button>
            </mat-menu>

            <button mat-menu-item routerLinkActive="mst-activeMenuItem" [matMenuTriggerFor]="videoAttributesFiltersMenu">
                ATTRIBUTI VIDEO
            </button>

            <mat-menu #videoAttributesFiltersMenu="matMenu">
                <button mat-menu-item *ngIf="!isNutritionist" routerLink="video-training-attributes">TRAINING</button>
                <button mat-menu-item routerLink="video-msb4you-attributes">MSB4YOU</button>
            </mat-menu>

            <!-- <a *ngIf="!isNutritionist" routerLinkActive="mst-activeMenuItem" routerLink="video-attributes">
                ATTRIBUTI VIDEO
            </a> -->
            <!-- GYM -->
            <button mat-menu-item *ngIf="!isNutritionist" routerLinkActive="mst-activeMenuItem" routerLink="gym/template/list">
                TEMPLATE GYM
            </button>
        </mat-menu>
        <a *ngIf="!isNutritionist" routerLinkActive="mst-activeMenuItem" routerLink="recipes">RICETTE</a>
        <a *ngIf="!isNutritionist" routerLinkActive="mst-activeMenuItem" routerLink="documents">DOCUMENTI</a>
        <a *ngIf="!isNutritionist" routerLinkActive="mst-activeMenuItem" routerLink="products">PRODOTTI</a>
        <a *ngIf="!isNutritionist" routerLinkActive="mst-activeMenuItem" routerLink="promotions">PROMOZIONI</a>
    </div>
    <div class="mst-header__rightToolbar">
        <a class="mst-header__logout" (click)="logout()">
            <mat-icon>logout</mat-icon>
        </a>
    </div>
</mat-toolbar>
