import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';

import { FileSaverService } from 'ngx-filesaver';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(
        private router: Router,
        private fileSaverService: FileSaverService,
    ) { }

    //
    // ─── ARRAY/OBJECT - METHODS ─────────────────────────────────────────────────────
    //

    arrayToObject(array: any, keyField: string): any {
        return array.reduce((obj: any, item: any) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});
    }

    objectToArray(object: any): any[] {
        return Object.keys(object).map(i => object[i]);
    }

    objectToObjectWithNewKey(object: any, keyField: string): any {
        return this.arrayToObject(Object.keys(object).map(i => object[i]), keyField);
    }

    sortArray(array: any[], propertyToSort: string): any[] {
        return array.sort((a: any, b: any) => {
            if (a[propertyToSort] < b[propertyToSort]) {
                return -1;
            } else if (a[propertyToSort] > b[propertyToSort]) {
                return 1;
            }
            return 0;
        });
    }

    sortReverseArray(array: any, propertyToSort: string): any[] {
        return array.sort((a: any, b: any) => {
            if (a[propertyToSort] > b[propertyToSort]) {
                return -1;
            } else if (a[propertyToSort] < b[propertyToSort]) {
                return 1;
            }
            return 0;
        });
    }

    removeUndefinedFromObject(object: any): any {
        Object.keys(object).forEach(
            (key: string) => {
                object[key] === undefined || object[key] === '' ? delete object[key] : '';
            }
        );
        return object;
    }

    removeUndefinedAndNullFromObject(object: any): any {
        Object.keys(object).forEach(
            (key: string) => {
                return object[key] === undefined || object[key] === null || object[key] === '' ? delete object[key] : '';
            })
            ;
        return object;
    }

    //
    // ─── FILE UTILS - METHODS ───────────────────────────────────────────────────────
    //

    base64ToArrayBuffer(base64: any): Uint8Array {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(name: string, mimeType: string, base64: any): void {
        const byte = this.base64ToArrayBuffer(base64);
        const blob = new Blob([byte], { type: mimeType });
        this.fileSaverService.save(blob, name);
    }

    //
    // ─── FORM UTILS - METHODS ───────────────────────────────────────────────────────
    //

    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    markFormGroupTouched(formGroup: UntypedFormGroup): void {
        (Object as any).values(formGroup.controls).forEach((control: any) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    //
    // ─── PWA UTILS - METHODS ────────────────────────────────────────────────────────
    //

    isInStandaloneMode(): boolean {
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    getCurrentPath(): string {
        return this.router.url.substr(1);
    }


}
