import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgProgressModule } from 'ngx-progressbar';

import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgProgressModule,
        NgProgressHttpModule,
        NgxMatSelectSearchModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
    ],

    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
