import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { APIInterceptor } from '@app/core/interceptors/api.interceptor';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';

import { AuthGuard } from './guards/auth.guard';
import { NutritionistCanViewProgressGuard } from './guards/nutritionist-can-view-progress.guard';
import { PermissionsExceptGuard } from './guards/permissions-except.guard';
import { PermissionsOnlyGuard } from './guards/permissions-only.guard';
import { RedirectGuard } from './guards/redirect.guard';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        AuthGuard,
        PermissionsOnlyGuard,
        PermissionsExceptGuard,
        RedirectGuard,
        NutritionistCanViewProgressGuard,
        // {
        //     provide: ErrorHandler,
        //     useClass: HandleErrorService
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {}
