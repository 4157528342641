import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';

import { Constants } from '@app/core/constants/constants';


@Injectable({
    providedIn: 'root'
})
export class RoleBasedPermissionsService {

    constructor(
        private constantsService: ConstantsService,
        private authService: AuthService,
    ) { }

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    checkAuthorizationOnly(authorizedRolesTags: string | string[]): Observable<boolean> {
        return new Observable((observer: any) => {
            this.constantsService.getConstants().subscribe(
                (constants: any) => {
                    const userData = this.authService.getUser();
                    const userRole = constants[Constants.USER_ROLES].find(
                        (role: any) => role.id === userData.role_id
                    );
                    observer.next(this.checkPermissionOnly(userRole.tag, authorizedRolesTags));
                }
            );
        });
    }

    checkAuthorizationExcept(authorizedRolesTags: string | string[]): Observable<boolean> {
        return new Observable((observer: any) => {
            this.constantsService.getConstants().subscribe(
                (constants: any) => {
                    const userData = this.authService.getUser();
                    const userRole = constants[Constants.USER_ROLES].find(
                        (role: any) => role.id === userData.role_id
                    );
                    observer.next(this.checkPermissionExcept(userRole.tag, authorizedRolesTags));
                }
            );
        });
    }

    checkPermissionOnly(userRoleTag: string, authorizedRolesTags: string | string[]): boolean {
        if (typeof authorizedRolesTags === 'string') {
            return userRoleTag === authorizedRolesTags;
        } else {
            return authorizedRolesTags.find(role => role === userRoleTag) !== undefined;
        }
    }

    checkPermissionExcept(userRoleTag: string, authorizedRolesTags: string | string[]): boolean {
        if (typeof authorizedRolesTags === 'string') {
            return userRoleTag !== authorizedRolesTags;
        } else {
            if (authorizedRolesTags.find((role: string) => role === userRoleTag) !== undefined) {
                return false;
            }
            return true;
        }
    }

    // checkPermissionExcept(pathsTags: string | string[]): boolean {
    //     if (typeof pathsTags === 'string') {
    //         return this.permissions.find(perm => perm === pathsTags) === undefined;
    //     } else {
    //         for (const tag of pathsTags) {
    //             if (this.permissions.find(perm => perm === tag) !== undefined) {
    //                 return false;
    //             }
    //         }
    //         return true;
    //     }
    // }
}
