// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Constants } from './core/constants/constants';
import { AuthGuard } from './core/guards/auth.guard';
import { NutritionistCanViewProgressGuard } from './core/guards/nutritionist-can-view-progress.guard';
import { PermissionsOnlyGuard } from './core/guards/permissions-only.guard';
import { RedirectGuard } from './core/guards/redirect.guard';
import { LayoutLoginComponent } from './shared/layouts/layout-login.component';
import { LayoutPagesComponent } from './shared/layouts/layout-pages.component';

type PathMatch = 'full' | 'prefix' | undefined;

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full' as PathMatch,
        children: [],
        canActivate: [AuthGuard, RedirectGuard],
    },
    {
        path: '',
        component: LayoutLoginComponent,
        children: [
            {
                path: 'login',
                loadChildren: () =>
                    import('./features/login/login.module').then((m: typeof import('./features/login/login.module')) => m.LoginModule),
            },
        ],
    },
    {
        path: '',
        component: LayoutPagesComponent,
        canActivate: [AuthGuard, PermissionsOnlyGuard],
        data: {
            rolesTag: [Constants.USER_ROLES_TAG.SUPER_ADMIN, Constants.USER_ROLES_TAG.ADMIN, Constants.USER_ROLES_TAG.NUTRITIONIST],
        },
        children: [
            {
                path: 'users',
                loadChildren: () =>
                    import('./features/users/users.module').then((m: typeof import('./features/users/users.module')) => m.UsersModule),
            },
            {
                path: 'users/:userId/nutrition-plans',
                loadChildren: () =>
                    import('./features/user-nutrition-plans/user-nutrition-plans.module').then(
                        (m: typeof import('./features/user-nutrition-plans/user-nutrition-plans.module')) => m.UserNutritionPlansModule
                    ),
            },
            {
                path: 'users/:userId/progress',
                canActivate: [NutritionistCanViewProgressGuard],
                loadChildren: () =>
                    import('./features/progress/progress.module').then(
                        (m: typeof import('./features/progress/progress.module')) => m.ProgressModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: LayoutPagesComponent,
        canActivate: [AuthGuard, PermissionsOnlyGuard],
        data: {
            rolesTag: [Constants.USER_ROLES_TAG.SUPER_ADMIN, Constants.USER_ROLES_TAG.ADMIN],
        },
        children: [
            {
                path: 'users-grid',
                loadChildren: () =>
                    import('./features/users-grid/users-grid.module').then(
                        (m: typeof import('./features/users-grid/users-grid.module')) => m.UsersGridModule
                    ),
            },
            {
                path: 'users/:userId/training-plans',
                loadChildren: () =>
                    import('./features/user-training-plans/user-training-plans.module').then(
                        (m: typeof import('./features/user-training-plans/user-training-plans.module')) => m.UserTrainingPlansModule
                    ),
            },
            {
                path: 'users/:userId/gym-plans',
                loadChildren: () =>
                    import('./features/user-gym-plans/user-gym-plans.module').then(
                        (m: typeof import('./features/user-gym-plans/user-gym-plans.module')) => m.UserGymPlansModule
                    ),
            },
            {
                path: 'videos-training',
                loadChildren: () =>
                    import('./features/videos/videos-training/videos-training.module').then(
                        (m: typeof import('./features/videos/videos-training/videos-training.module')) => m.VideosTrainingModule
                    ),
            },
            {
                path: 'videos-msb4you',
                loadChildren: () =>
                    import('./features/videos/videos-msb4you/videos-msb-foryou.module').then(
                        (m: typeof import('./features/videos/videos-msb4you/videos-msb-foryou.module')) => m.VideosMsbForyouModule
                    ),
            },
            {
                path: 'videos-gym',
                loadChildren: () =>
                    import('./features/videos/videos-gym/videos-gym.module').then(
                        (m: typeof import('./features/videos/videos-gym/videos-gym.module')) => m.VideosGymModule
                    ),
            },
            {
                path: 'recipes',
                loadChildren: () =>
                    import('./features/recipes/recipes.module').then(
                        (m: typeof import('./features/recipes/recipes.module')) => m.RecipesModule
                    ),
            },
            {
                path: 'documents',
                loadChildren: () =>
                    import('./features/documents/documents.module').then(
                        (m: typeof import('./features/documents/documents.module')) => m.DocumentsModule
                    ),
            },
            // {
            //     path: 'gym-trainings-editing',
            //     loadChildren: () => import('./features/gym/gym-trainings/gym-trainings.module').then(m => m.GymTrainingsModule)
            // },
            {
                path: 'gym/template',
                loadChildren: () =>
                    import('./features/gym/gym.module').then((m: typeof import('./features/gym/gym.module')) => m.GymModule),
            },
            {
                path: 'gym-training-editing',
                loadChildren: () =>
                    import('./features/gym-program-editing/gym-program-editing.module').then(
                        (m: typeof import('./features/gym-program-editing/gym-program-editing.module')) => m.GymProgramEditingModule
                    ),
            },
            {
                path: 'video-training-attributes',
                loadChildren: () =>
                    import('./features/video-attributes/video-attributes-training/video-training-attributes.module').then(
                        (m: typeof import('./features/video-attributes/video-attributes-training/video-training-attributes.module')) =>
                            m.VideoTrainingAttributesModule
                    ),
            },
            {
                path: 'video-msb4you-attributes',
                loadChildren: () =>
                    import('./features/video-attributes/video-attributes-msb4you/video-msb4you-attributes.module').then(
                        (m: typeof import('./features/video-attributes/video-attributes-msb4you/video-msb4you-attributes.module')) =>
                            m.VideoMSB4YouAttributesModule
                    ),
            },
            {
                path: 'products',
                loadChildren: () =>
                    import('./features/products/products.module').then(
                        (m: typeof import('./features/products/products.module')) => m.ProductsModule
                    ),
            },
            {
                path: 'users/:userId/anamnesis',
                loadChildren: () =>
                    import('./features/nutrition-anamnesis/nutrition-anamnesis.module').then(
                        (m: typeof import('./features/nutrition-anamnesis/nutrition-anamnesis.module')) => m.NutritionAnamnesisModule
                    ),
            },
            {
                path: 'promotions',
                loadChildren: () =>
                    import('./features/promotions/promotions.module').then(
                        (m: typeof import('./features/promotions/promotions.module')) => m.PromotionsModule
                    ),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'users-grid',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
