import { Injectable } from '@angular/core';

import { Constants } from '@app/core/constants/constants';
// import { User } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageManagementService {

    constructor() { }

    //
    // ─── GENERIC - METHODS ──────────────────────────────────────────────────────────
    //

    getLocalStorage(): string {
        return JSON.stringify(localStorage);
    }

    getItem(item: string): string | null {
        return localStorage.getItem(item);
    }

    setItem(item: string, value: any): void {
        localStorage.setItem(item, value);
    }

    removeItem(item: string): void {
        localStorage.removeItem(item);
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    //
    // ─── TOKEN - METHODS ────────────────────────────────────────────────────────────
    //


    getToken(): string | null {
        return localStorage.getItem(Constants.TOKEN_NAME);
    }

    setToken(token: string): void {
        localStorage.setItem(Constants.TOKEN_NAME, token);
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(Constants.REFRESH_TOKEN_NAME);
    }

    setRefreshToken(token: string): void {
        localStorage.setItem(Constants.REFRESH_TOKEN_NAME, token);
    }

    deleteTokens(): void {
        localStorage.removeItem(Constants.TOKEN_NAME);
        localStorage.removeItem(Constants.REFRESH_TOKEN_NAME);
    }

    //
    // ─── USER - METHODS ─────────────────────────────────────────────────────────────
    //

    getUser(): any {
        return JSON.parse(localStorage.getItem(Constants.USER) as string) as any
    }

    setUser(user: any): void {
        localStorage.setItem(Constants.USER, JSON.stringify(user));
    }

    deleteUser(): void {
        localStorage.removeItem(Constants.USER);
    }
}
