import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { throwError, Observable, Subject, EMPTY } from 'rxjs';
import { catchError, tap, finalize, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/base/auth.service';
import { LocalStorageManagementService } from '../services/base/local-storage-management.service';

import { Constants } from '@app/core/constants/constants';
import { AuthResponse } from '@app/shared/models/auth-response.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService: any;
    private refreshTokenInProgress = false;

    private tokenRefreshedSource = new Subject();
    // tslint:disable-next-line: member-ordering
    public tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        private injector: Injector,
        private router: Router,
        private localStorageManagementService: LocalStorageManagementService
    ) { }

    addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        if (localStorage.getItem(Constants.TOKEN_NAME)) {
            return request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem(Constants.TOKEN_NAME)
                }
            });
        }
        return request;
    }

    refreshToken(): Observable<any> {
        if (this.refreshTokenInProgress) {
            return new Observable((observer: any) => {
                this.tokenRefreshed$.subscribe((response: any) => {
                    observer.next(response);
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.authService.refreshToken().pipe(
                tap((response: AuthResponse) => { this.tokenRefreshedSource.next(response); }),
                finalize(() => (this.refreshTokenInProgress = false))
            );
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        this.authService = this.injector.get(AuthService);
        this.localStorageManagementService = this.injector.get(LocalStorageManagementService);

        // Handle request
        request = this.addAuthHeader(request);
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (
                    error.status === 401 &&
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('token/refresh')
                ) {
                    if (this.localStorageManagementService.getRefreshToken()) {
                        return this.refreshToken().pipe(
                            tap((response: AuthResponse) => {
                                this.localStorageManagementService.setToken(response.token);
                                this.localStorageManagementService.setRefreshToken(response.refresh_token);
                            }),
                            catchError(() => {
                                this.localStorageManagementService.clearLocalStorage();
                                this.router.navigate(['/login']);
                                return EMPTY;
                            }),
                            switchMap(() => {
                                request = this.addAuthHeader(request);
                                return next.handle(request);
                            }),
                            finalize(() => {
                                this.refreshTokenInProgress = false;
                            })
                        );
                    } else {
                        this.localStorageManagementService.clearLocalStorage();
                        this.router.navigate(['/login']);
                        return EMPTY;
                    }
                }
                return throwError(error);
            })
        );
    }

}
