import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { RedirectService } from '../services/base/redirect.service';
import { RoleBasedPermissionsService } from '../services/base/role-based-permissions.service';


@Injectable({
    providedIn: 'root'
})
export class PermissionsExceptGuard  {
    constructor(
        private redirectService: RedirectService,
        private roleBasedPermissions: RoleBasedPermissionsService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return new Promise((resolve: any) => {
            this.roleBasedPermissions.checkAuthorizationExcept(route.data.rolesTag).subscribe(
                (consent: boolean) => {
                    if (consent) {
                        resolve(true);
                    } else {
                        this.redirectService.redirectBasedOnUserRole();
                        resolve(false);
                    }
                }, () => {
                    this.router.navigate(['login']);
                    resolve(false);
                }
            );
        });
    }
}
