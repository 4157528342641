import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { VERSION } from '@env/version';

@Component({
    selector: 'mst-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    ngOnInit(): void {
        this.setupAppVersion();
    }

    //
    // ─── VERSIONS - METHODS ─────────────────────────────────────────────────────────
    //

    setupAppVersion(): void {
        const version = this.document.createElement('script');
        version.type = 'text/javascript';
        version.text = `window.appVersion = function() { return '${VERSION.version} - ${VERSION.hash}' }`;
        this.document.body.appendChild(version);
    }
}
