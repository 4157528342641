import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/core/constants/constants';
import { AuthService } from '@app/core/services/base/auth.service';
import { ConstantsService } from '@app/core/services/base/constants.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';
import { UtilsService } from '@app/core/services/base/utils.service';
import { format, subWeeks } from 'date-fns';

/**
 * @title Toolbar overview
 */
@Component({
    selector: 'mst-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {

    public isNutritionist = false;

    private userRolesByID!: any;
    private userRolesByTag!: any;
    private constantsFE: any;

    constructor(
        private authService: AuthService,
        private localStorageService: LocalStorageManagementService,
        private constantsService: ConstantsService,
        private router: Router,
        private utilsService: UtilsService
    ) {
        this.constantsFE = Constants;

    }

    ngOnInit(): void {
        this.getConstants();
    }

    getConstants(): void {
        this.constantsService.getConstants().subscribe(
            (response: any) => {
                this.userRolesByID = this.utilsService.arrayToObject(response[this.constantsFE.USER_ROLES], 'id');
                this.userRolesByTag = this.utilsService.arrayToObject(response[this.constantsFE.USER_ROLES], 'tag');

                const activeUser = this.localStorageService.getUser();
                // tslint:disable-next-line: max-line-length
                this.isNutritionist = this.userRolesByID[activeUser.role_id].tag === this.userRolesByTag[Constants.USER_ROLES_TAG.NUTRITIONIST].tag;
            }
        );
    }

    logout(): void {
        this.authService.logout().subscribe();
    }

    navigateToUsers(): void {
        this.router.navigate(['/users'],
            {
                queryParams: {
                    page: 0,
                    limit: 20,
                }
            });
    }

}
