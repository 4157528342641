import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/base/auth.service';
import { LocalStorageManagementService } from '../services/base/local-storage-management.service';

@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private authService: AuthService,
        private localStorageManagementService: LocalStorageManagementService
    ) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isTokenExpired()) {
            if (this.localStorageManagementService.getRefreshToken()) {
                return this.authService.refreshToken().pipe(
                    switchMap(() => of(true)),
                    catchError(() => {
                        this.router.navigate(['/login']);
                        return of(false);
                    })
                );
            } else {
                this.router.navigate(['/login']);
                return of(false);
            }
        } else {
            return true;
        }
    }

}
