import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '@app/shared/models/user.model';

import { LocalStorageManagementService } from './local-storage-management.service';

import { fromUnixTime, differenceInMinutes } from 'date-fns';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userData?: User | null;

    constructor(
        private localStorageManagementService: LocalStorageManagementService,
        private http: HttpClient,
        private router: Router
    ) {}

    //
    // ─── HTTP REQUEST - POST ────────────────────────────────────────────────────────
    //

    login(email: string, password: string, rememberMe: boolean): Observable<any> {
        const params = {
            email: email,
            password: password,
            remember_me: rememberMe,
        };
        return this.http.post<any>('login', params).pipe(
            tap((user: any) => {
                this.localStorageManagementService.setToken(user.token);
                this.localStorageManagementService.setRefreshToken(user.refresh_token);
            })
        );
    }

    logout(): Observable<any> {
        return this.http.post<any>('logout', {}).pipe(
            tap(
                () => {
                    this.localStorageManagementService.deleteTokens();
                    this.localStorageManagementService.deleteUser();
                    this.clearUserData();
                    this.router.navigate(['/login']);
                },
                () => {
                    this.localStorageManagementService.deleteTokens();
                    this.localStorageManagementService.deleteUser();
                    this.clearUserData();
                    this.router.navigate(['/login']);
                }
            )
        );
    }

    deleteUser(): Observable<any> {
        return this.http.delete<any>('profile', {}).pipe(
            tap(() => {
                this.localStorageManagementService.deleteTokens();
                this.localStorageManagementService.deleteUser();
                this.clearUserData();
                this.router.navigate(['/login']);
            })
        );
    }

    //
    // ─── TOKEN METHODS ──────────────────────────────────────────────────────────────
    //

    getTokenExpirationDate(token: string): Date | null {
        try {
            const decoded: any = jwt_decode(token);
            if (decoded.exp === undefined) {
                return null;
            }

            return fromUnixTime(decoded.exp);
        } catch (error) {
            return null;
        }
    }

    isTokenExpired(token?: string): boolean {
        const authToken = token || this.localStorageManagementService.getToken();
        if (!authToken) {
            return true;
        }

        const date = this.getTokenExpirationDate(authToken);

        if (!date) {
            return true;
        }

        return differenceInMinutes(date, new Date()) <= 0;
    }

    refreshToken(): Observable<any> {
        const params = {
            refresh_token: this.localStorageManagementService.getRefreshToken(),
        };

        return this.http.post<any>('token/refresh', params).pipe(
            tap(
                (response: any) => {
                    this.localStorageManagementService.setToken(response.token);
                    this.localStorageManagementService.setRefreshToken(response.refresh_token);
                    return true;
                },
                () => {
                    this.logout().subscribe();
                    return false;
                }
            )
        );
    }

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    getUser(): any {
        if (!this.userData) {
            this.userData = this.localStorageManagementService.getUser();
        }
        return this.userData;
    }

    getLoggedUser(): Observable<User> {
        return this.http.get<User>('logged_user', {}).pipe(
            tap((user: User) => {
                this.localStorageManagementService.setUser(user);
            })
        );
    }

    updateUser(user: User): void {
        this.userData = user;
    }

    clearUserData(): any {
        this.userData = null;
    }
}
