import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { RedirectService } from '../services/base/redirect.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard  {

    constructor(
        private redirectService: RedirectService
    ) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.redirectService.redirectBasedOnUserRole();
        return false;
    }

}
